<template>
  <div class="home Ukij" style="background-size: contain; height: 100vh;" oncontextmenu="return false"
    ondragstart="return false">
    <!-- 公司名称 -->
    <div style="padding-top: 30px; color: #fff; font-weight: bold">گۇاڭجۇ گۈللەيا سودا چەكلىك شىركىتى</div>

    <h3 style="direction: rtl;margin-top: 15px; color: #ff6c00;">مۇكاپات چىقىش نىسبىتى %100</h3>

    <div class="canyu">جەمئىي {{totalCount}} ئادەم قاتناشتى</div>

    <!-- 抽奖转盘 -->
    <div class="Dflex">
      <div class="rotate">
        <img src="../assets/002.png" class="dish" :style="{ transform: rotate_deg, transition: rotate_transition }">
        <img src="../assets/003.png" class="pointer" @click="start">
      </div>

      <h2 style="margin-top: 230px;" class="rtl">جەمئىي پۇرسەت: <span style="color: #F56C6C;">{{ luckClick }} </span>
        قېتىم </h2>
    </div>

    <!-- 额度使用对话框 -->
    <el-dialog class="Ukij" v-model="eduDialog" title="ئەسكەرتىش" width="300" center>
      <div style="width: 100%; direction: rtl; color: #F56C6C;"> كەچۈرڭ پۇرسىتىڭىز تۈگەپتۇ !</div>
      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" class="Ukij" @click="eduDialog = false">تامام</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 填写手机号弹出框 -->
    <el-dialog class="Ukij" v-model="dialogCreatPhone" title="تىزىملىتىڭ" width="300">

      <!-- <el-input v-model="name" class="creatInput" placeholder="ئىسىم-فامىلىڭىزنى كىرگۈزۈڭ" /> -->
      <el-input v-model="phoneNum" class="creatInput" placeholder="تىلفۇن نۇمۇرىڭىزنى كىرگۈزۈڭ" />

      <div class="row flex-end tishi"> : ئەسكەرتىش</div>
      <div class="rtl row tishi">1 يۈەن تۈلەپ پائالىيەتكە قاتنىشىڭ .</div>

      <template #footer>
        <div class="dialog-footer">
          <el-button class="Ukij" @click="closedialogCreatPhone">تاقاش</el-button>
          <el-button class="Ukij" type="primary" @click="confirmCreatePhone">
            جەزىملەش
          </el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 奖励提示对话框 -->
    <el-dialog class="Ukij" v-model="jiangLiDialog" title="مۇكاپات" width="300" center>
      <!-- <div></div> -->
      <div style="text-align: center;">
        <img :src="imagePath" style="width: 230px;"></img>
        <div>! مۇبارەك بولسۇن <span style="color: #F56C6C;">{{ jiangLi }}</span> مۇكاپات چىقتى</div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" class="Ukij" @click="jiangLiDialog = false">تامام</el-button>
        </div>
      </template>
    </el-dialog>

    <!-- 未付款提示 -->
    <el-dialog class="Ukij" v-model="noPlayLiDialog" title="ئەسكەرتىش" width="300" center>
      <!-- <div></div> -->
      <div style="text-align: center;">
        <img src="../assets/noPlay.svg" style="width: 100px;"></img>
        <div class="Ukij rtl" style="color: #F56C6C;">پۈل تۈلىمەي قاتنىشىۋالاي دىگنمىتىلە ؟؟؟</div>
      </div>

      <template #footer>
        <div class="dialog-footer">
          <el-button type="primary" class="Ukij" @click="onClickPayMent">ماقۇل تۈلەي</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { addUser, getjiangpinnum, getUserInfo, getwxuserInfo, reqLogin, changeUserInfo, getUser, payment, getjiangpin, chanjpcount } from '../../request/api.js';
import { ElMessage } from 'element-plus';

export default {
  name: 'HomeView',
  data() {
    return {
      name: '',
      phoneNum: '',
      dialogCreatPhone: false,
      jiangLiDialog: false,
      eduDialog: false,
      jiangLi: '',
      winIndex: 7,
      totalCount: 0,

      luckClick: 0, //剩余次数
      rotate_deg: 0, // 转盘的旋转角度
      rotate_transition: 'transform 3s ease-in-out', // 初始化过渡属性控制
      isClick: true,
      queryParams: '', // 路由参数
      imgaeUrl: '', // 奖品图片
      mukapat_num: '', // 奖品图片
      noPlayLiDialog: false,
    }
  },
  created() {
    this.isQrScans(); //验证是否扫码
    this.clearStorage();
    if (!this.isCode()) this.reqLoginFun();
    
    this.getPost();
  },
  computed: {
    imagePath() {
      return require(`@/assets/${this.imgaeUrl}.png`); // 动态生成图片路径
    }
  },
  methods: {
    // 获取地址参数
    isQrScans () {
      let QRCode = sessionStorage.getItem('QRCode');
      if (!QRCode || QRCode == 123) {
        this.$router.push({ path: '/validate/123' });
      }
    },
    
    // 清除缓存
    clearStorage() {
      localStorage.clear();
    },
    
    getPost () {
      getUser().then(res => {
        if(res.status == 200) {
          this.totalCount = res.data.totalCount;
        }
        // this.totalCount = res.data.total_user[0] ? res.data.total_user[0].canyu_ren : 0;
      })

      getjiangpinnum().then(res => {
        this.mukapat_num = res.data[0].num;
      })
    },

    // 检查是否注册
    isLogin(){
      let isCreate = localStorage.getItem("phone_num");
      if (isCreate) {
        this.dialogCreatPhone = false;

        getUserInfo(isCreate).then( res => {
          if(res.status == 200) {
            this.luckClick = res.data[0].balance;
          }
        } ).catch( err => {
          console.log("err: ", err)
        })

      } else {
        this.dialogCreatPhone = true;
      }
    },

    // 把 code 和 用户信息 存到缓存
    isCode() {
      if (window.location.search.includes('code=')) {
        localStorage.setItem('isCode', this.$route.query.code)
        let code = this.$route.query.code
        let data = { code }
        getwxuserInfo(data).then(res => {
          const userInfo = res.data.data
          const token = res.data.token
          localStorage.setItem("userInfo", JSON.stringify(userInfo))
          localStorage.setItem("token", token)
          
          this.userId = res.data.data.id;

          // 手机号
          this.isLogin();
        }).catch()
      }
      return localStorage.getItem("isCode") !== null
    },   

    // 登录请求
    reqLoginFun () {
      reqLogin().then(res => {
        if (res.status == 200) {
          window.location.href = res.data
        }
      }).catch()
    },

    // 关闭对话框
    closedialogCreatPhone() {
      this.dialogCreatPhone = false;
    },

    // 确认提交
    confirmCreatePhone() {
      let jpcon = {};
      let remain = 0;

      getjiangpin(this.mukapat_num).then(async res => {
        if (res.status == 200) {
          console.log("res: ", res);
          jpcon = await res.data[0];
          remain = await res.data[0].remain;

          jpcon.remain = parseInt(remain, 10) - 1;
          chanjpcount(jpcon).then(res => { }).catch(err => { })
        }
      }).catch(err => { })
      
      let jiang_ping = this.mukapat_num;
      
      let phone_num = this.phoneNum;
      let user_id = this.userId;
      let data = { phone_num, user_id, jiang_ping }
      addUser(data).then( async res => {
        if(res.status == 200) {
          this.dialogCreatPhone = false;
          localStorage.setItem("phone_num", phone_num)
          
          this.onClickPayMent();
          
          getUserInfo(phone_num).then(res => {
            this.luckClick = res.data[0].balance;
          }).catch(err => {
            console.log("err: ", err)
          })

          this.getPost();

          ElMessage({
            message: 'مۇۋاپىقيەتلىك بولدى',
            type: 'success',
          })
        }
      } ).catch(error => {
        this.dialogCreatPhone = false;

        ElMessage({
          message: 'تىزىملىنىپ بولغان نۇمۇر',
          type: 'error',
        })
      })
    },

    // 转盘按钮
    start() {
      let phoneNum = localStorage.getItem("phone_num");
      getUserInfo(phoneNum).then( res => {
        
        if(this.luckClick == 0) {
          this.eduDialog = true;
          return;
        }
        this.rotating();
        
        let phone_num = localStorage.getItem("phone_num");
        let balance = this.luckClick;
        let data = { phone_num, balance }
        changeUserInfo(data).then(res => {})

      } ).catch( err => {
        console.log("err: ", err)
      })
      
    },

    // 转盘旋转业务逻辑
    rotating() {
      if (!this.isClick) return;
      this.isClick = false;
      this.rotate_transition = 'transform 3s ease-in-out';

      // 减少抽奖次数
      this.luckClick--;
      let rand_circle = 5; // 默认多旋转5圈

      // 设置抽奖的概率
      let winIndex = this.set();

      let randomDeg = 360 - winIndex * 45;

      let deg = rand_circle * 360 + randomDeg

      // 最终旋转
      this.rotate_deg = `rotate(${deg}deg)`;
      
      let that = this;
      setTimeout(() => {
        that.isClick = true;
        that.rotate_deg = `rotate(${0}deg)`;
        that.rotate_transition = '';
        if(winIndex == 3) {
          this.jiangLi = 'ئەتىر';
          this.imgaeUrl = '6';
          this.jiangLiDialog = true;
        } 
        else if (winIndex == 2) {
          this.jiangLi = 'كۆز قورۇق يۈرۈشلىگى';
          this.imgaeUrl = '7';
          this.jiangLiDialog = true;
        }
        else if (winIndex == 4) {
          this.jiangLi = 'ئالتۇن ئۈزۈك';
          this.imgaeUrl = '5';
          this.jiangLiDialog = true;
        } 
        else if (winIndex == 0) {
          this.jiangLi = '猎豹CS9 ماشىنا';
          this.imgaeUrl = '1';
          this.jiangLiDialog = true;
        } 
        else if (winIndex == 6) {
          this.jiangLi = 'توك سىكىلىت';
          this.imgaeUrl = '3';
          
          this.jiangLiDialog = true;
        } 
        else if (winIndex == 1) {
          this.jiangLi = 'يۈز چاپلاق';
          this.imgaeUrl = '8';
          this.jiangLiDialog = true;
        } 
        else if (winIndex == 5) {
          this.jiangLi = 'كىر ئالغۇ';
          this.imgaeUrl = '4';
          this.jiangLiDialog = true;
        } 
        else if (winIndex == 7) {
          this.jiangLi = '比亚迪G6 ماشىنا';
          this.imgaeUrl = '2';
          this.jiangLiDialog = true;
        }
      }, 3500);
    },

    // 设置抽奖概率
    set() {
      // let winIndex = 1;
      
      // 7 转盘 BYD 对话框 面膜
      // 6 转盘 摩托车 对话 眼膜
      // 5 转盘 冰箱 对话 香水
      // 4 转盘 戒指 对话 戒指
      // 3 转盘 香水 对话 冰箱
      
      // let winIndex = this.winIndex;
      let winIndex = this.mukapat_num;
      // let rand = Math.random();
      // if (rand < 0.3) winIndex = 5;
      // else if (rand < 0.55) winIndex = 6;
      // else if (rand < 0.75) winIndex = 4;
      // else if (rand < 0.85) winIndex = 0;
      // else if (rand < 0.92) winIndex = 2;
      // else if (rand < 0.97) winIndex = 1;
      // else if (rand < 0.99) winIndex = 3;
      // else if (rand == 0.99) winIndex = 7;
      return winIndex;
    },
    
    // 支付
    onClickPayMent () {
      let vipDays = this.vip_days
      let vipType = this.vipType
      let data = { bodyCon: '抽奖活动', price: 100 }
      let _this = this;
      payment(data).then(res => {
        function onBridgeReady () {
          WeixinJSBridge.invoke(
            'getBrandWCPayRequest', {
            "appId": res.data.appId,     //公众号ID，由商户传入     
            "timeStamp": res.data.timeStamp,         //时间戳，自1970年以来的秒数     
            "nonceStr": res.data.nonceStr, //随机串     
            "package": res.data.package,
            "signType": res.data.signType,         //微信签名方式：     
            "paySign": res.data.paySign //微信签名 
          },
            function (res) {
              if (res.err_msg == "get_brand_wcpay_request:ok") {
                let userInfo = JSON.parse(localStorage.getItem("userInfo"))
                let user_id = userInfo.id
                // let user_id = 1
                let vip_days = vipDays
                let type = vipType
                let data = { user_id, vip_days, type }
                updateUserAuth(data).then(res => {
                  // alert(`支付成功，发起更新请求也成功了: ${res}`)
                  alert('支付成功')
                  // _this.openPaymentInfoDialog();
                  _this.noPlayLiDialog = false;
                }).catch(err => { })
                // 使用以上方式判断前端返回,微信团队郑重提示：
                //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
              } else {
                this.noPlayLiDialog = true;
              }
            });
        }
        if (typeof WeixinJSBridge == "undefined") {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', onBridgeReady);
            document.attachEvent('onWeixinJSBridgeReady', onBridgeReady);
          }
        } else {
          onBridgeReady();
        }
      }).catch(err => {
        console.log("err: ", err)
      })
    },
  }
}
</script>

<style>
.home {
  background-image: url(../assets/BJ.jpg);
  background-repeat: no-repeat;
}
.creatInput {
  width: 240px;
  margin-bottom: 15px;
  direction: rtl;
}
input::placeholder {
  font-family: "Ukij";
}
.el-message__content {
  font-family: "Ukij";
  direction: rtl;
}



.Dflex {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
* { margin: 0; padding: 0;}
.dish { width: 100%;}
.rotate {
  position: relative;
  width: 300px;
  height: 200px;
}
.pointer {
  position: absolute;
  left: 50%;
  top: 70%;
  transform: translate(-50%, -50%);
  /* width: 100px; */
  height: 100px;
}
.canyu {
  margin-top: 30px;
  background-color: rgb(224, 50, 50);
  height: 50px;
  color: white;
  line-height: 50px;
  width: 60%;
  margin-left: 20%;
  border-radius: 8px;
}

.tishi { color: #fd6b03; }
</style>
